<template>
    <v-container class="w-container-filter-res-alert v-overflow-filter-res filterResourcesAlert"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                    background-color: white;
                    color: black;
                    font-weight: bold;
                    padding: 4px;
                    font-size: 10px;
                    min-width:100vw;
                    border-color:#da6a2d">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Company </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                done-Text="" :value="selAzienda" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                    :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>



                </v-col>

            </v-row>

        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Company
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">

                                <v-img alt="Proprietario Logo" class="  " contain src="@/assets/btn_cancel3_alertmsg.png"
                                    max-width="24" max-height="24" style="margin-right:13px"
                                    transition="scale-transition" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>
                      

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="" src="@/assets/aziende-alertmsg.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                </v-list-item>

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </v-container>
</template>
    
<script>
import apiusers from "../utils/users/apiusers";

import {
    bus
} from "../main";

import router from ".././router";
import $ from 'jquery';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        this.$root.$children[0].verifyTrialPeriod();

        this.$root.$children[0].deleteAttachTemp();

        var pointerVue = this;

        pointerVue.$root.$children[0].totalMsgBaseRis = 0;
        pointerVue.$root.$children[0].totalMsgRefs = 0;

        this.setupButtons();

        setTimeout(() => {

            this.syncAziende();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_confirm":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/medici"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });

        });

        pointerVue.hideShowButtons(false);

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({
        titolo: "User Messages",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        selAzienda: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        sheetAziende: false,

        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        // nucleiRules

        select: null,

        //gruppi: window.$cookies.get("gen_settings").gruppi,

        checkbox: false,
    }),

    methods: {

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.selAzienda = azienda.companyid;

            this.hideShowButtons(true);

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [];

                }

                if (v_enable == true) {

                    pulsantis = [{
                        text: "Confirm",
                        icon: "mdi-content-save",
                        image: "https://app.emtool.eu/public/_lib/img/ok-alertmsg.png",
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        title: "Confirm",
                        width: 30
                    },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/ok-alertmsg.png",
                    link: "/timesheet",
                    id: "btn_confirm",
                    disabled: false,
                    width: 30
                    //class: "inverted",
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            console.log("AZIENDA: ", that.selAzienda);

            window.$cookies.set("sel_filter_azienda_utenti", that.selAzienda, "9y");

            router.push({
                path: "/resources"
            });

        },

        // saveData: async function () {

        //     var that = this;

        //     console.log("ID: " + that.$route.params.id);

        //     var response = null;

        //     if (that.$route.params.id == 0)
        //     {

        //         that.showSpinner = true;
        //         that.$root.$children[0].showProgress = true;

        //         response = await apimedico.insertMedico(
        //             that.medico,
        //             $("#nuclei").val()
        //         ).then((res) => {

        //             that.$root.$children[0].showProgress = false;
        //             console.log("res from insertMedico", res);

        //             that.$swal({
        //                 icon: "success",
        //                 text: "Dati salvati correttamente",
        //                 showConfirmButton: false,
        //                 timer: 2000
        //             });

        //             setTimeout(() => {

        //                 that.dialogMsg = false;
        //                 that.setupButtons();

        //                 router.push({
        //                     path: "/medici"
        //                 });

        //             }, 200);

        //         }).catch(err => {
        //                 that.$root.$children[0].showProgress = false;
        //                 console.log(err);
        //                 var msg = err.response.data.Error;

        //                 that.$swal({
        //                     icon: "error",
        //                     text: msg,
        //                     showConfirmButton: false,
        //                     timer: 8000
        //                 });
        //                 console.log("ERrori", "Non è stato possibile salvare i dati");
        //                 console.log("response", response);

        //             }

        //         );

        //     }

        //     if (that.$route.params.id > 0)
        //     {

        //         that.showSpinner = true;
        //         that.$root.$children[0].showProgress = true;

        //         response = await apimedico.updateMedico(
        //             that.medico,
        //             $("#nuclei").val(),
        //             that.$route.params.id
        //         ).then((res) => {

        //             that.$root.$children[0].showProgress = false;
        //             console.log("res from updateMedico", res);

        //             that.$swal({
        //                 icon: "success",
        //                 text: "Dati salvati correttamente",
        //                 showConfirmButton: false,
        //                 timer: 2000
        //             });

        //             setTimeout(() => {

        //                 that.dialogMsg = false;
        //                 that.setupButtons();

        //                 router.push({
        //                     path: "/medici"
        //                 });

        //             }, 200);

        //         }).catch(err => {
        //                 that.$root.$children[0].showProgress = false;
        //                 console.log(err);
        //                 var msg = err.response.data.Error;

        //                 that.$swal({
        //                     icon: "error",
        //                     text: msg,
        //                     showConfirmButton: false,
        //                     timer: 8000
        //                 });
        //                 console.log("ERrori", "Non è stato possibile salvare i dati");
        //                 console.log("response", response);

        //             }

        //         );

        //     }

        // },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getAziende(
                v_token,
                "alertmsg"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziende", res);

                try {

                    this.aziende = res.data.company;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>
    
<style>

.w-container-filter-res-alert .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}


.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-filter-res-alert {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.filterResourcesAlert .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.filterResourcesAlert .v-label {
    font-size: 11px!important;
    color:black!important;
    font-weight: bold!important;
}


.filterResourcesAlert .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color:black!important;
    font-weight: bold!important;
}

.filterResourcesAlert ion-select {
    font-size: 11px !important;
    color:black!important;
    font-weight: bold!important;
}

.v-overflow-filter-res
{
    overflow-x: hidden!important;
    overflow-y: hidden!important;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-filter-res-alert {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
    