<template>
    <v-container class="w-container v-overflow editCompanyAgilaAlertMsg" style="padding-top:59px">


        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="min-width:1000px" v-model="valid" ref="form" lazy-validation>


            <v-row>
                <v-col cols="12" md="12" style="font-weight: bold;font-size: 13px;">
                    Informazioni Generali
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.fld_name" id="fld_name" label="Ragione Sociale">
                    </v-text-field>

                </v-col>

                <v-col cols="3" md="3">

                    <ion-item id="itemTipo"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                        <ion-label style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                            position="floating">Tipo </ion-label>

                        <ion-select id="idtipo_azienda" name="idtipo_azienda" class='idtipo_azienda' cancel-Text="Chiudi"
                            done-Text="" :value="azienda.idtipo_azienda" interface="action-sheet">

                            <ion-select-option v-for="item in tipo" :key="item.idtipo_azienda" :value='item.idtipo_azienda'>
                                {{ item.descrizione }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.email" label="E-Mail"></v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.url_site" label="Sito Web"></v-text-field>

                </v-col>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.sdi" label="SDI"></v-text-field>

                </v-col>

                <v-col cols="3" md="3">

                    <ion-item id="itemSettoreAttività"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                        <ion-label style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                            position="floating">Settore Attività </ion-label>

                        <ion-select id="id_settore_attivita" name="id_settore_attivita" class='id_settore_attivita'
                            cancel-Text="Chiudi" done-Text="" :value="azienda.id_settore_attivita" interface="action-sheet">

                            <ion-select-option v-for="item in settoriattivita" :key="item.id" :value='item.id'> {{
                                item.descrizione
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.mail_pec" label="PEC"></v-text-field>

                </v-col>

                <v-col cols="3" md="3">

                    <ion-item id="itemSedeEstera"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                        <ion-label style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                            position="floating">Sede Estera </ion-label>

                        <ion-select id="sede_estera" name="sede_estera" class='sede_estera' cancel-Text="Chiudi"
                            done-Text="" :value="azienda.sede_estera" interface="action-sheet">

                            <ion-select-option v-for="item in sedeestera" :key="item.id" :value='item.id'> {{
                                item.descrizione
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.codice_ateco" label="Codice Ateco"></v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="3" md="3">

                    <div id="cont_refid" style="cursor:pointer">

                        <ion-item id="itemReferente"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Referente Unico </ion-label>

                            <ion-select id="is_ref_unico" name="is_ref_unico" class='is_ref_unico' cancel-Text="Chiudi"
                                done-Text="" :value="azienda.is_ref_unico" interface="action-sheet">

                                <ion-select-option v-for="item in valori_sino" :key="item.id" :value='item.id'> {{
                                    item.descrizione
                                }}
                                </ion-select-option>

                            </ion-select>
                        </ion-item>


                    </div>

                </v-col>

                <v-col cols="3" md="3" style="margin-top:23px">

                    <span class="files">
                        <label style="margin-left:11px;font-size:11px;color:black!important;font-weight:bold;">Logo</label>
                        <input type="file" id="input-file-logo" name="logo" accept=".png,.jpg,.jpeg" class="dropify"
                            data-max-width="6610" data-max-height="6200" />
                    </span>

                </v-col>


                <v-col cols="3" md="3">

                    <div id="cont_statoid" style="cursor:pointer">

                        <ion-item id="itemStato"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Stato </ion-label>

                            <ion-select id="stato" name="stato" class='stato' cancel-Text="Chiudi" done-Text=""
                                :value="azienda.stato" interface="action-sheet">

                                <ion-select-option v-for="item in stati" :key="item.id" :value='item.id'> {{
                                    item.nome
                                }}
                                </ion-select-option>

                            </ion-select>
                        </ion-item>


                    </div>

                </v-col>






            </v-row>

            <v-row>
                <v-col cols="12" md="12" style="font-weight: bold;font-size: 13px;">
                    Legale
                </v-col>
            </v-row>


            <v-row>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.address_legal" label="Indirizzo"></v-text-field>

                </v-col>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.cap_legal" label="CAP"></v-text-field>

                </v-col>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.citta_legal" label="Città"></v-text-field>

                </v-col>


            </v-row>

            <v-row>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.provincia_legal" label="Provincia"></v-text-field>

                </v-col>


                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.regione_legale" label="Regione"></v-text-field>

                </v-col>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.nazione_legal" label="Nazione"></v-text-field>

                </v-col>


            </v-row>

            <v-row>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.partita_iva" label="Partita Iva"></v-text-field>

                </v-col>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.codice_fiscale" label="Codice Fiscale">
                    </v-text-field>

                </v-col>


            </v-row>

            <v-row>
                <v-col cols="12" md="12" style="font-weight: bold;font-size: 13px;">
                    Operativo
                </v-col>
            </v-row>

            <v-row>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.address_operative" label="Indirizzo"
                        id="indirizzo_operativo"></v-text-field>

                </v-col>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.cap_operative" label="CAP"></v-text-field>

                </v-col>


                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.citta_operative" label="Città"></v-text-field>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.provincia_operative" label="Provincia">
                    </v-text-field>

                </v-col>


                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.regione_operativa" label="Regione"></v-text-field>

                </v-col>



                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.phone_number" label="Telefono"></v-text-field>

                </v-col>



            </v-row>

            <v-row>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.fax_number" label="Fax">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row style="margin-top:0px!important;margin-bottom:36px!important">

                <v-col cols="12" md="12" style="margin-top:0px!important">

                    <div style="font-size:11px;font-weight:bold;display:flex">

                        <img src="/alertmsg/img/copy_elements.png" class="cl-copy-element" />
                        <div style="margin-top:2px;margin-left:4px;">
                            Copia informazioni comuni dalla sede legale
                        </div>

                    </div>


                </v-col>

            </v-row>



            <v-row v-if="showReferenti">
                <v-col cols="12" md="12" style="font-weight: bold;font-size: 13px;">
                    Referenti
                </v-col>
            </v-row>

            <v-row v-if="showReferenti">

                <v-col cols="12" md="12">

                    <div id="refsData" style="margin-left: 12px">

                        <div style="display:flex;width:100%">
                            <div style="font-weight:bold;width:5%;font-size: 11px;">N.</div>
                            <div style="font-weight:bold;width:20%;font-size: 11px">Referente</div>
                            <div style="font-weight:bold;width:20%;font-size: 11px">Azienda</div>
                            <div style="font-weight:bold;width:25%;font-size: 11px">Dipartimento</div>
                            <div style="font-weight:bold;width:30%;font-size: 11px">Mail</div>
                        </div>

                        <v-list-item class="text-left" v-for="item in referenti" :key="item.n_row">

                            <div style="display:flex;width:100%">
                                <div style="width:5%;transform: translateX(-32px);font-size: 11px;font-weight:bold">{{
                                    item.n_row
                                }}</div>
                                <div style="width:20%;transform: translateX(-33px);font-size: 11px;font-weight:bold">{{
                                    item.referente
                                }}</div>
                                <div style="width:20%;transform: translateX(-23px);font-size: 11px;font-weight:bold">{{
                                    item.azienda
                                }}</div>
                                <div style="width:25%;transform: translateX(-11px);font-size: 11px;font-weight:bold">{{
                                    item.dipartimento
                                }}
                                </div>
                                <div style="width:30%;font-weight: 11px;font-weight:bold">{{ item.mail }}</div>
                            </div>



                        </v-list-item>



                    </div>

                </v-col>

            </v-row>


            <v-row>
                <v-col cols="12" md="12" style="font-weight: bold;font-size: 13px;">
                    Altro
                </v-col>
            </v-row>

            <v-row>

                <v-col cols="3" md="3">

                    <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="azienda.data_apertura" label="Data Atto Costituzione" persistent-hint
                                readonly v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="azienda.data_apertura" no-title @input="showPicker = false"
                            locale="it"></v-date-picker>
                    </v-menu>

                </v-col>

                <v-col cols="3" md="3">

                    <ion-item id="itemChiusuraEsercizio"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                        <ion-label style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                            position="floating">Chiusura Esercizio </ion-label>

                        <ion-select id="tipo_chiusura_esercizio" name="tipo_chiusura_esercizio"
                            class='tipo_chiusura_esercizio' cancel-Text="Chiudi" done-Text=""
                            :value="azienda.tipo_chiusura_esercizio" interface="action-sheet">

                            <ion-select-option v-for="item in tipochiusura" :key="item.id" :value='item.id'> {{
                                item.descrizione
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>



                </v-col>

                <v-col v-if="enableMonth" cols="3" md="3">


                    <ion-item id="itemMese"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                        <ion-label style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                            position="floating">Mese </ion-label>

                        <ion-select id="mese_chiusura_esercizio" name="mese_chiusura_esercizio"
                            class='mese_chiusura_esercizio' cancel-Text="Chiudi" done-Text=""
                            :value="azienda.mese_chiusura_esercizio" interface="action-sheet">

                            <ion-select-option v-for="item in mesi" :key="item.value" :value='item.value'> {{
                                item.desc_month
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>




                </v-col>

            </v-row>


            <v-row>

                <v-col cols="3" md="3">

                    <div id="cont_commid" style="cursor:pointer">

                        <ion-item id="itemCommercialista"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Commercialista </ion-label>

                            <ion-select id="id_commercialista" name="id_commercialista" class='id_commercialista'
                                cancel-Text="Chiudi" done-Text="" :value="azienda.id_commercialista"
                                interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in commercialisti" :key="item.id" :value='item.id'> {{
                                    item.ragione_sociale
                                }}
                                </ion-select-option>

                            </ion-select>
                        </ion-item>


                    </div>

                </v-col>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.n_dipendenti" label="Tot. Dipendenti">
                    </v-text-field>

                </v-col>

                <v-col cols="3" md="3">

                    <div id="cont_refagilaid" style="cursor:pointer">

                        <ion-item id="itemRefAgila"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Referente Agila </ion-label>

                            <ion-select id="ref_commerciale_int" name="ref_commerciale_int" class='ref_commerciale_int'
                                cancel-Text="Chiudi" done-Text="" :value="azienda.ref_commerciale_int"
                                interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in referenti_agila" :key="item.login" :value='item.login'> {{
                                    item.full_name }}
                                </ion-select-option>

                            </ion-select>
                        </ion-item>


                    </div>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="3" md="3">

                    <ion-item id="itemFormaGiuridica"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                        <ion-label style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                            position="floating">Forma Giuridica </ion-label>

                        <ion-select id="forma_societaria" name="forma_societaria" class='forma_societaria'
                            cancel-Text="Chiudi" done-Text="" :value="azienda.forma_societaria" interface="action-sheet">

                            <ion-select-option v-for="item in formagiuridica" :key="item.id" :value='item.id'> {{
                                item.descrizione
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

                <v-col cols="3" md="3">

                    <ion-item id="itemControllataCollegata"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                        <ion-label style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                            position="floating">Controllata Collegata </ion-label>

                        <ion-select id="associata_controllata" name="associata_controllata" class='associata_controllata'
                            cancel-Text="Chiudi" done-Text="" :value="azienda.associata_controllata"
                            interface="action-sheet">

                            <ion-select-option v-for="item in associatacontrollata" :key="item.id" :value='item.id'> {{
                                item.descrizione
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

                <v-col cols="3" md="3">

                    <ion-item id="itemProprietario"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                        <ion-label style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                            position="floating">Proprietario </ion-label>

                        <ion-select id="proprietario" name="proprietario" class='proprietario' cancel-Text="Chiudi"
                            done-Text="" :value="azienda.proprietario" interface="action-sheet">

                            <ion-select-option v-for="item in proprietari" :key="item.id" :value='item.id'> {{
                                item.nome
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>


            </v-row>


            <v-row>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.amministratore_delegato" label="Amministratore Delegato">
                    </v-text-field>

                </v-col>

                <v-col cols="3" md="3">

                    <v-text-field v-model="azienda.segnalatore" label="Segnalatore">
                    </v-text-field>

                </v-col>


                <v-col cols="3" md="3">

                    <ion-item id="itemDimAzi"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-9px;margin-bottom:18px">

                        <ion-label style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                            position="floating">Dimensioni Azienda </ion-label>

                        <ion-select id="dimensione_azienda" name="dimensione_azienda" class='dimensione_azienda'
                            cancel-Text="Chiudi" done-Text="" :value="azienda.dimensione_azienda" interface="action-sheet">

                            <ion-select-option v-for="item in dimensioneazienda" :key="item.id" :value='item.id'> {{
                                item.descrizione
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>


            </v-row>


            <v-row>

                <v-col cols="3" md="3">

                    <v-textarea v-model="azienda.ulteriori_info" label="Ulteriori Informazioni Azienda">
                    </v-textarea>

                </v-col>


            </v-row>



        </v-form>






        <div class="contgruppi">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetGruppi" class="vgruppisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetGruppi" fixed style="position:fixed;">
                                Gruppi
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetGruppi = !sheetGruppi">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchGruppi">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredGruppi"
                                        :key="item.idresources_group_name" @click="manageClickGruppi(item)">
                                        <v-icon aria-hidden="false">mdi-account-multiple</v-icon>
                                        <v-list-item-title class='itemGruppo'>{{ item.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>


        <div class="contpopupdoxwesize">

            <template>
                <modal name="popupDoxwebSize" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Max size (MB)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreDoxwebSize" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldDoxwebSize" id="fldDoxwebSize"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-doxwebsize" contain src="@/assets/btn_cancel3_alertmsg.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-doxwebsize" contain
                                    src="@/assets/btn_confirm3_alertmsg.png" max-width="32" max-height="32"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contcommercialisti">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetCommercialisti" class="vgruppisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetGruppi" fixed style="position:fixed;">
                                Commercialisti
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetCommercialisti = !sheetCommercialisti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchCommercialisti">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredCommercialisti" :key="item.id"
                                        @click="manageClickCommercialisti(item)">
                                        <v-icon aria-hidden="false">mdi-account-multiple</v-icon>
                                        <v-list-item-title class='itemGruppo'>{{ item.ragione_sociale }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>


        <div class="contrefagila">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetRefAgila" class="vgruppisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetGruppi" fixed style="position:fixed;">
                                Referenti
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetRefAgila = !sheetRefAgila">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchRefAgila">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredRefAgila" :key="item.login"
                                        @click="manageClickRefAgila(item)">
                                        <v-icon aria-hidden="false">mdi-account-multiple</v-icon>
                                        <v-list-item-title class='itemGruppo'>{{ item.full_name }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>



    </v-container>
</template>

<script>
import apipianoorario from "../utils/pianoorario/apipianoorario";
//import apirisorse from "../utils/risorse/apirisorse";
import apiaziende from "../utils/aziende/apiaziende";

import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';


export default ({
    mounted() {

        /*eslint-disable no-undef*/

        //  this.$root.$children[0].verifyTrialPeriod();

        this.$root.$children[0].checkLogout();

        Vue.use(VModal, {
            dialog: true
        });


        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;

        var oggDrop = $('.dropify').dropify({
            messages: {
                default: 'Drag',
                replace: /* s */ '',
                remove: 'Rimuovi',
                error: 'File troppo grande'
            }
        });

        $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
        $(".dropify-render").append("<img>");

        oggDrop.on('dropify.afterClear', function (event, element) {

            console.log(event, element);

            pointerVue.attachRemove = true;

        });

        this.cur_id = this.$route.params.id;

        this.setupButtons();

        if (this.$route.params.id == 0) {

            this.initInsertAzienda();


        }

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Azienda";

            this.initEditAzienda();

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/companyAgila"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {


            $('body').on('click', '.cl-copy-element', function (event) {
                console.log(event);

                console.log("AZIENDA: ", pointerVue.azienda);


                pointerVue.azienda.address_operative = pointerVue.azienda.address_legal;

                pointerVue.azienda.cap_operative = pointerVue.azienda.cap_legal;
                pointerVue.azienda.citta_operative = pointerVue.azienda.citta_legal;
                pointerVue.azienda.provincia_operative = pointerVue.azienda.provincia_legal;

                pointerVue.azienda.regione_operativa = pointerVue.azienda.regione_legale;




            });



            $('body').on('click', '#cont_refagilaid', function (event) {
                console.log(event);

                pointerVue.sheetRefAgila = true;

            });




            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });

            $('body').on('click', '#cont_departmentid', function (event) {
                console.log(event);

                pointerVue.sheetDipartimento = true;

            });

            $('body').on('click', '#cont_companycustomer', function (event) {
                console.log(event);

                pointerVue.sheetAziendeClienti = true;

            });


            $('body').on('click', '#cont_customer_id', function (event) {
                console.log(event);

                pointerVue.sheetClienti = true;

            });



            $('body').on('click', '#cont_login', function (event) {
                console.log(event);

                pointerVue.sheetUtentiAssociati = true;

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '.img_copy_rim', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreRimanente;

            });

            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');

                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.pagamento.importo = pointerVue.valoreDaPagare;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '#d_pagato', function (event) {
                console.log(event);

                if (pointerVue.$route.params.id == 0) {

                    pointerVue.$modal.show('popupImportoPagato');

                }

                if (pointerVue.$route.params.id > 0) {

                    pointerVue.$modal.show('popupImportoPagatoRimanente');

                }

            });

            $('body').on('click', '.btn-confirm-importo-pagato', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-cancel-importo-pagato', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-confirm-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            $('body').on('click', '.btn-cancel-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });



            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

            });

            $(".lunedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".martedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".mercoledi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".giovedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".venerdi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".sabato").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".domenica").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });


            $('body').on('ionChange', '.tipo_chiusura_esercizio', function (event) {
                console.log(event);

                console.log("VAL SEL: ", this.value);

                if (this.value == "I") {

                    pointerVue.enableMonth = true;


                }
                else {

                    pointerVue.enableMonth = false;

                }

            });





            $('body').on('ionChange', '.stato', function (event) {
                console.log(event);

                pointerVue.azienda.stato = this.value;

            });






            $('body').on('click', '#d_ferie_ap', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupFerieAP');

            });

            $('body').on('click', '.btn-confirm-ferieap', function (event) {
                console.log(event);

                pointerVue.risorsa.ferie_ap = pointerVue.valoreFerieAP;

                pointerVue.$root.$children[0].setFieldActive("#d_ferie_ap");

                pointerVue.$modal.hide('popupFerieAP');

            });

            $('body').on('click', '.btn-cancel-ferieap', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFerieAP');

            });



            $('body').on('click', '#d_ferie_ac', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupFerieAC');

            });





            $('body').on('click', '.btn-confirm-ferieac', function (event) {
                console.log(event);

                pointerVue.risorsa.ferie_ac = pointerVue.valoreFerieAC;

                pointerVue.$root.$children[0].setFieldActive("#d_ferie_ac");

                pointerVue.$modal.hide('popupFerieAC');

            });

            $('body').on('click', '.btn-cancel-ferieac', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFerieAC');

            });




            $('body').on('click', '#d_permessi_ap', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPermessiAP');

            });

            $('body').on('click', '.btn-confirm-permessiap', function (event) {
                console.log(event);

                pointerVue.risorsa.permessi_ap = pointerVue.valorePermessiAP;

                pointerVue.$root.$children[0].setFieldActive("#d_permessi_ap");

                pointerVue.$modal.hide('popupPermessiAP');

            });

            $('body').on('click', '.btn-cancel-permessiap', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupPermessiAP');

            });




            $('body').on('click', '#d_permessi_ac', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPermessiAC');

            });

            $('body').on('click', '.btn-confirm-permessiac', function (event) {
                console.log(event);

                pointerVue.risorsa.permessi_ac = pointerVue.valorePermessiAC;

                pointerVue.$root.$children[0].setFieldActive("#d_permessi_ac");

                pointerVue.$modal.hide('popupPermessiAC');

            });

            $('body').on('click', '.btn-cancel-permessiac', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupPermessiAC');

            });




            $('body').on('click', '#d_proroga', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupProroga');

            });

            $('body').on('click', '.btn-confirm-proroga', function (event) {
                console.log(event);

                pointerVue.risorsa.proroga = pointerVue.valoreProroga;

                pointerVue.$root.$children[0].setFieldActive("#d_proroga");

                pointerVue.$modal.hide('popupProroga');

            });

            $('body').on('click', '.btn-cancel-proroga', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupProroga');

            });





            $('body').on('click', '#d_lordomese', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupLordoMese');

            });

            $('body').on('click', '.btn-confirm-lordomese', function (event) {
                console.log(event);

                pointerVue.risorsa.lordo_mese = pointerVue.valoreLordoMese;

                pointerVue.$root.$children[0].setFieldActive("#d_lordomese");

                pointerVue.$modal.hide('popupLordoMese');

            });

            $('body').on('click', '.btn-cancel-lordomese', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupLordoMese');

            });




            $('body').on('click', '#d_diaria', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupDiaria');

            });

            $('body').on('click', '.btn-confirm-diaria', function (event) {
                console.log(event);

                pointerVue.risorsa.diaria = pointerVue.valoreDiaria;

                pointerVue.$root.$children[0].setFieldActive("#d_diaria");

                pointerVue.$modal.hide('popupDiaria');

            });

            $('body').on('click', '.btn-cancel-diaria', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDiaria');

            });




            $('body').on('click', '#d_tarif_net_h', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupTariffNet');

            });

            $('body').on('click', '.btn-confirm-tnetta', function (event) {
                console.log(event);

                pointerVue.risorsa.tariffa_netto = pointerVue.valoreTariffaNetta;

                pointerVue.$root.$children[0].setFieldActive("#d_tarif_net_h");

                pointerVue.$modal.hide('popupTariffNet');

            });

            $('body').on('click', '.btn-cancel-tnetta', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupTariffNet');

            });




            $('body').on('click', '#d_cost_giorn', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupCostGiorn');

            });

            $('body').on('click', '.btn-confirm-cgiorn', function (event) {
                console.log(event);

                pointerVue.risorsa.costo_giornaliero = pointerVue.valoreCostGiorn;

                pointerVue.$root.$children[0].setFieldActive("#d_cost_giorn");

                pointerVue.$modal.hide('popupCostGiorn');

            });

            $('body').on('click', '.btn-cancel-cgiorn', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupCostGiorn');

            });

            $('body').on('click', '#d_ebadge_pausa', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupEbadgePausa');

            });

            $('body').on('click', '.btn-confirm-ebadgepausa', function (event) {
                console.log(event);

                pointerVue.risorsa.min_pausa = pointerVue.valoreEbadgePausa;

                pointerVue.$root.$children[0].setFieldActive("#d_ebadge_pausa");

                pointerVue.$modal.hide('popupEbadgePausa');

            });

            $('body').on('click', '.btn-cancel-ebadgepausa', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupEbadgePausa');

            });



            $('body').on('ionChange', '.is_ebadge', function (event) {
                console.log(event);


                if (this.value == "S") {

                    pointerVue.showRow1 = true;
                    pointerVue.showRow3 = true;
                    pointerVue.showRow4 = true;
                    pointerVue.showRow5 = true;
                    pointerVue.showRow6 = true;

                }

                if (this.value == "N") {

                    pointerVue.showRow1 = false;
                    pointerVue.showRow2 = false;
                    pointerVue.showRow3 = false;
                    pointerVue.showRow4 = false;
                    pointerVue.showRow5 = false;
                    pointerVue.showRow6 = false;


                }


            });


            $('body').on('ionChange', '.idebadge_type_registration', function (event) {
                console.log(event);


                console.log("VAL: ", this.value);

                if ((this.value == 2) || (this.value == 3)) {
                    pointerVue.showRow2 = true;
                }

                if ((this.value != 2) && (this.value != 3)) {
                    pointerVue.showRow2 = false;
                }

            });

            $('body').on('click', '#d_penalita_intervallo', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPenalitaIntervallo');

            });

            $('body').on('click', '.btn-confirm-penalitaintervallo', function (event) {
                console.log(event);

                pointerVue.risorsa.min_margine = pointerVue.valorePenalitaIntervallo;

                pointerVue.$root.$children[0].setFieldActive("#d_penalita_intervallo");

                pointerVue.$modal.hide('popupPenalitaIntervallo');

            });

            $('body').on('click', '.btn-cancel-penalitaintervallo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupPenalitaIntervallo');

            });




            $('body').on('click', '#d_penalita_mm', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPenalitaMM');

            });

            $('body').on('click', '.btn-confirm-penalitamm', function (event) {
                console.log(event);

                pointerVue.risorsa.min_penalty = pointerVue.valorePenalitaMM;

                pointerVue.$root.$children[0].setFieldActive("#d_penalita_mm");

                pointerVue.$modal.hide('popupPenalitaMM');

            });

            $('body').on('click', '.btn-cancel-penalitamm', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupPenalitaMM');

            });





            $('body').on('click', '#cont_piano_orario', function (event) {
                console.log(event);

                pointerVue.sheetPianoOrario = true;




            });




            $('body').on('click', '#cont_group_id', function (event) {
                console.log(event);

                pointerVue.sheetGruppi = true;

            });

            $('body').on('click', '#cont_commid', function (event) {
                console.log(event);

                pointerVue.sheetCommercialisti = true;

            });





            $('body').on('click', '#d_doxweb_maxsize', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupDoxwebSize');

            });

            $('body').on('click', '.btn-confirm-doxwebsize', function (event) {
                console.log(event);

                pointerVue.azienda.doxmaxsize = pointerVue.valoreDoxwebSize;

                pointerVue.$root.$children[0].setFieldActive("#d_doxweb_maxsize");

                pointerVue.$modal.hide('popupDoxwebSize');

            });

            $('body').on('click', '.btn-cancel-doxwebsize', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDoxwebSize');

            });



        });

    },

    components: {
        VueAutonumeric,
    },

    computed: {




        filteredRefAgila() {

            return _.orderBy(this.referenti_agila.filter(item => {
                if (!this.searchRefAgila) return this.referenti_agila;
                return (item.full_name.toLowerCase().includes(this.searchRefAgila.toLowerCase()));
            }), 'headline')
        },


        filteredCommercialisti() {

            return _.orderBy(this.commercialisti.filter(item => {
                if (!this.searchCommercialisti) return this.commercialisti;
                return (item.ragione_sociale.toLowerCase().includes(this.searchCommercialisti.toLowerCase()));
            }), 'headline')
        },


        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredAziendeClienti() {

            return _.orderBy(this.aziendeclienti.filter(item => {
                if (!this.searchAziendeClienti) return this.aziendeclienti;
                return (item.fld_name.toLowerCase().includes(this.searchAziendeClienti.toLowerCase()));
            }), 'headline')
        },

        filteredDipartimenti() {

            return _.orderBy(this.dipartimenti.filter(item => {
                if (!this.searchDipartimento) return this.dipartimenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipartimento.toLowerCase()));
            }), 'headline')
        },

        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        filteredUtentiAssociati() {

            return _.orderBy(this.utentiassociati.filter(item => {
                if (!this.searchUtentiAssociati) return this.utentiassociati;
                return (item.full_name_usr.toLowerCase().includes(this.searchUtentiAssociati.toLowerCase()));
            }), 'headline')
        },



        filteredClienti() {

            return _.orderBy(this.aziendeclienti.filter(item => {
                if (!this.searchClienti) return this.aziendeclienti;
                return (item.fld_name.toLowerCase().includes(this.searchClienti.toLowerCase()));
            }), 'headline')
        },


        filteredPianoOrario() {

            return _.orderBy(this.pianiorario.filter(item => {
                if (!this.searchPianoOrario) return this.pianiorario;
                return (item.descrizione.toLowerCase().includes(this.searchPianoOrario.toLowerCase()));
            }), 'headline')
        },




        filteredGruppi() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },






        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({


        stati: [{
            id: "A",
            nome: "Attiva"
        }, {
            id: "N",
            nome: "Non Attiva"
        }, {
            id: "F",
            nome: "Fallita"
        }],



        showReferenti: false,


        tab: null,


        items: [
            'Informazioni Generali', 'Legale', 'Operativo', 'Referenti', 'Altro'
        ],



        loadLogo: 1,
        nomeFileLogo: "",



        referenti: [],

        sheetRefAgila: false,
        searchRefAgila: "",

        sheetCommercialisti: false,
        searchCommercialisti: "",


        valori_sino: [{ id: "S", descrizione: "Si" }, { id: "N", descrizione: "No" }],

        valoreFerieAP: null,
        valoreFerieAC: null,
        valorePermessiAP: null,
        valorePermessiAC: null,
        valoreProroga: null,
        valoreLordoMese: null,
        valoreDiaria: null,
        valoreTariffaNetta: null,
        valoreCostGiorn: null,
        valoreEbadgePausa: null,
        valorePenalitaIntervallo: null,
        valorePenalitaMM: null,
        valoreDoxwebSize: null,

        showPicker3: false,
        showPicker4: false,
        showPicker5: false,
        showPicker6: false,
        showPicker7: false,
        enableMonth: false,

        showRow1: false,
        showRow2: false,
        showRow3: false,
        showRow4: false,
        showRow5: false,
        showRow6: false,

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        valTotale: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,

        azienda: { partita_iva: "", codice_fiscale: "", address_operative: "", cap_operative: "", citta_operative: "", provincia_operative: "", regione_operativa: "", stato: "A" },

        dipendenti: [],
        tipidocumento: [],
        tipolaurea: [],
        contratti: [],
        interviste: [],
        tiporiscontro: [],
        tipocontratto: [],
        sedelavorativa: [],
        qualifica: [],
        ebadgetiporegistrazione: [],
        valoripianoorario: [],
        tipo: [],
        settoriattivita: [],
        gruppi: [],
        mesi: [],
        proprietari: [],
        commercialisti: [],

        referenti_agila: [],


        genere: [{
            id: "M",
            description: "Maschio"
        }, {
            id: "F",
            description: "Femmina"
        }],

        situazionefamigliare: [{
            id: "Celibe / Nubile",
            description: "Celibe / Nubile"
        }, {
            id: "Coniugato",
            description: "Coniugato"
        }, {
            id: "Separato",
            description: "Separato"
        }],

        sedeestera: [{
            id: "S",
            descrizione: "Si"
        }, {
            id: "N",
            descrizione: "No"
        }],

        tipochiusura: [{
            id: "I",
            descrizione: "Infrannuale"
        }, {
            id: "S",
            descrizione: "Anno Solare"
        }],

        formagiuridica: [
            { id: "SNC", descrizione: "SNC" },
            { id: "SRL", descrizione: "SRL" },
            { id: "SPA", descrizione: "SPA" },
            { id: "SAS", descrizione: "SAS" },
            { id: "dind", descrizione: "Ditta Individuale" },
            { id: "SS", descrizione: "SS" },
            { id: "SRLS", descrizione: "SRLS" },
            { id: "SAPA/SAA", descrizione: "SAPA/SAA" },
            { id: "SocCoop", descrizione: "SocCoop" }
        ],

        associatacontrollata: [{
            id: "S",
            descrizione: "Si"
        }, {
            id: "N",
            descrizione: "No"
        }],

        dimensioneazienda: [{
            id: "Micro",
            descrizione: "Micro"
        }, {
            id: "Piccola",
            descrizione: "Piccola"
        }, {
            id: "Media",
            descrizione: "Media"
        }, {
            id: "Grande",
            descrizione: "Grande"
        }],

        valorisino: [{
            id: "S",
            descrizione: "Si"
        }, {
            id: "N",
            descrizione: "No"
        }],







        currAzienda: "",
        titolo: "Aggiungi Azienda",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetDipartimento: false,
        searchDipartimento: "",

        sheetTipologie: false,
        searchTipologie: "",

        sheetAziendeClienti: false,
        searchAziendeClienti: "",

        sheetUtentiAssociati: false,
        searchUtentiAssociati: "",

        sheetPianoOrario: false,
        searchPianoOrario: "",


        sheetClienti: false,
        searchClienti: "",


        sheetGruppi: false,
        searchGruppi: "",



        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: ""
        },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: [],
        dipartimenti: [],
        dipartimentiTemp: [],

        aziendeclienti: [],
        aziendeclientiTemp: [],

        utentiassociati: [],
        utentiassociatiTemp: [],

        sediassunzioneTemp: [],
        sediassunzione: [],

        pianiorarioTemp: [],
        pianiorario: [],

    }),

    methods: {


        onExpansionPanelClick2(event) {
            if (event.target.classList.contains('v-expansion-panel-header--active')) {
                console.log("Panel is closing/now closed.")
            } else {

                setTimeout(() => {

                    if (this.$route.params.id == 0) {



                        $("#refsData").hide();
                    }

                }, 100);



            }
        },






        setupGridPianoOrario: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;



            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.columns = [{
                    data: 'codice',
                    orderable: false,
                    name: 'codice',
                    width: '2%'
                },

                {
                    data: 'descrizione',
                    orderable: false,
                    name: 'descrizione',
                    width: '2%'
                },







                ];



                window.table = $('#myTablePianoOrario').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("EmtPianoOrarioApi") + '?company_id=7&username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            var valSearch = $("#myTablePianoOrario_filter input").val();

                            var search = {
                                "value": $("#myTablePianoOrario_filter input").val(),
                                "regex": false
                            };

                            if (valSearch.length > 0) {
                                valori.search = search;
                                valori.columns = window.columns;
                                console.log(window.columns);
                            }

                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTablePianoOrario_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTablePianoOrario_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("EmtPianoOrarioApi") + '?company_id=7&username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTablePianoOrario_wrapper .row .col-md-6").first().remove();

                $("#myTablePianoOrario_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                

                </div>
            `);



            });

        },







        onExpansionPanelClick(e) {

            console.log("EVENT: ", e);

            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });


                console.log("FILE LOGO: ", that.nomeFileLogo);
                console.log("FILE LOGO: ", that.loadLogo);

                if (that.loadLogo == 1) {

                    that.loadLogo = 0;

                    if (that.nomeFileLogo != "") {


                        var txt_image = that.getUrlDomain("public/documents/" + that.nomeFileLogo);


                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 100);


                    }



                }



            }, 100);


        },

        updateTotal() {

            console.log("LUNEDI: ", $(".lunedi").val());

            var v_lunedi = 0;
            if ($(".lunedi").val() != "") {
                v_lunedi = $(".lunedi").val();
            }

            var v_martedi = 0;
            if ($(".martedi").val() != "") {
                v_martedi = $(".martedi").val();
            }

            var v_mercoledi = 0;
            if ($(".mercoledi").val() != "") {
                v_mercoledi = $(".mercoledi").val();
            }

            var v_giovedi = 0;
            if ($(".giovedi").val() != "") {
                v_giovedi = $(".giovedi").val();
            }

            var v_venerdi = 0;
            if ($(".venerdi").val() != "") {
                v_venerdi = $(".venerdi").val();
            }

            var v_sabato = 0;
            if ($(".sabato").val() != "") {
                v_sabato = $(".sabato").val();
            }

            var v_domenica = 0;
            if ($(".domenica").val() != "") {
                v_domenica = $(".domenica").val();
            }

            var v_tot = parseFloat(v_lunedi) + parseFloat(v_martedi) + parseFloat(v_mercoledi) + parseFloat(v_giovedi) + parseFloat(v_venerdi) + parseFloat(v_sabato) + parseFloat(v_domenica);

            console.log("TOT: ", v_tot);

            this.valTotale = v_tot.toFixed(2);

        },

        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },



        manageClickRefAgila: async function (refagila) {

            console.log("AZI SEL: ", refagila);

            this.sheetRefAgila = false;

            this.azienda.ref_commerciale_int = refagila.login;


        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.risorsa.companyid = azienda.companyid;

            this.setDipartimenti(azienda.companyid);

            this.setAziendeClienti(azienda.companyid);

            this.setUtenti(azienda.companyid);

            this.setSediAssunzione(azienda.companyid);

            this.setPianiOrari(azienda.companyid);

        },

        manageClickPianoOrario: async function (pianoorario) {

            console.log("PO SEL: ", pianoorario);

            this.sheetPianoOrario = false;

            this.risorsa.codice_piano_orario = pianoorario.codice;



        },



        manageClickGruppi: async function (gruppo) {

            console.log("GRP SEL: ", gruppo);

            this.sheetGruppi = false;

            this.azienda.group_id = gruppo.idresources_group_name;



        },




        setPianiOrari: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.pianiorarioTemp);

            var pianiOrari = [];

            for (var i = 0; i < this.pianiorarioTemp.length; i++) {

                if (parseInt(this.pianiorarioTemp[i].companyid) == parseInt(id_azienda)) {

                    pianiOrari.push({
                        codice: this.pianiorarioTemp[i].codice,
                        descrizione: this.pianiorarioTemp[i].descrizione,
                        lunedi: this.pianiorarioTemp[i].lunedi,
                        martedi: this.pianiorarioTemp[i].martedi,
                        mercoledi: this.pianiorarioTemp[i].mercoledi,
                        giovedi: this.pianiorarioTemp[i].giovedi,
                        venerdi: this.pianiorarioTemp[i].venerdi,
                        sabato: this.pianiorarioTemp[i].sabato,
                        domenica: this.pianiorarioTemp[i].domenica,
                        total: parseFloat(this.pianiorarioTemp[i].total).toFixed(2),



                    });

                }

            }

            console.log("PO SEL: ", pianiOrari);

            this.pianiorario = pianiOrari;

        },




        setDipartimenti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.dipartimentiTemp);

            var dipartimentiAzienda = [];

            for (var i = 0; i < this.dipartimentiTemp.length; i++) {

                if (parseInt(this.dipartimentiTemp[i].companyid) == parseInt(id_azienda)) {

                    dipartimentiAzienda.push({
                        departmentid: this.dipartimentiTemp[i].departmentid,
                        fld_name: this.dipartimentiTemp[i].fld_name
                    });

                }

            }

            console.log("DIP SEL: ", dipartimentiAzienda);

            this.dipartimenti = dipartimentiAzienda;

        },

        setAziendeClienti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.aziendeclientiTemp);

            var aziendeClienti = [];

            for (var i = 0; i < this.aziendeclientiTemp.length; i++) {

                if (parseInt(this.aziendeclientiTemp[i].our_company) == parseInt(id_azienda)) {

                    aziendeClienti.push({
                        companyid: this.aziendeclientiTemp[i].companyid,
                        fld_name: this.aziendeclientiTemp[i].fld_name
                    });

                }

            }

            console.log("AZI CLI SEL: ", aziendeClienti);

            this.aziendeclienti = aziendeClienti;

        },

        setUtenti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("UTENTI TMP: ", this.utentiassociatiTemp);

            var utentiAssociati = [];

            for (var i = 0; i < this.utentiassociatiTemp.length; i++) {

                if (parseInt(this.utentiassociatiTemp[i].idcompany_logo) == parseInt(id_azienda)) {

                    utentiAssociati.push({
                        login: this.utentiassociatiTemp[i].login,
                        full_name_usr: this.utentiassociatiTemp[i].full_name_usr
                    });

                }

            }

            console.log("UT ASS SEL: ", utentiAssociati);

            this.utentiassociati = utentiAssociati;

        },




        setSediAssunzione: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("SEDI ASS TMP: ", this.sediassunzioneTemp);

            var sediAssunzione = [];

            for (var i = 0; i < this.sediassunzioneTemp.length; i++) {

                if (parseInt(this.sediassunzioneTemp[i].companyid) == parseInt(id_azienda)) {

                    sediAssunzione.push({
                        idsedeassunzione: this.sediassunzioneTemp[i].idsedeassunzione,
                        descrizione: this.sediassunzioneTemp[i].descrizione
                    });

                }

            }

            console.log("SEDI ASS SEL: ", sediAssunzione);

            this.sediassunzione = sediAssunzione;

        },





        manageClickDipartimenti: async function (dipartimento) {

            console.log("DIP SEL: ", dipartimento);

            this.sheetDipartimento = false;

            this.risorsa.departmentid = dipartimento.departmentid;

        },

        manageClickAziendeClienti: async function (aziendacliente) {

            console.log("AZI CLI SEL: ", aziendacliente);

            this.sheetAziendeClienti = false;

            this.risorsa.companycustomer = aziendacliente.companyid;

        },


        manageClickCommercialisti: async function (commercialista) {

            console.log("COMM SEL: ", commercialista);

            this.sheetCommercialisti = false;

            this.azienda.id_commercialista = commercialista.id;

        },


        manageClickClienti: async function (aziendacliente) {

            console.log("AZI CLI SEL: ", aziendacliente);

            this.sheetClienti = false;

            this.risorsa.customer_id = aziendacliente.companyid;

        },



        manageClickUtentiAssociati: async function (utente) {

            console.log("UTENTE SEL: ", utente);

            this.sheetUtentiAssociati = false;

            this.risorsa.login = utente.login;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [

                    {
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/save-alertmsg.png",
                        title: "Salva",
                        width: 30

                    },

                    {
                        text: "Indietro",
                        icon: "mdi-arrow-left",
                        image: "https://app.emtool.eu/public/_lib/img/back-alertmsg.png",
                        link: "/tipologiapagamento",
                        id: "btn_back",
                        disabled: false,
                        title: "Indietro",
                        width: 35
                    },




                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            var errore = "";

            console.log("AZIENDA VAL: ", that.azienda);

            if (that.$route.params.id == 0) {

                // if ($("#code").val() == undefined) {
                //     errore = "Sezione Informazioni Generali campo codice richiesto";
                // }
                // else {
                //     if ($("#code").val() == "") {
                //         errore = "Sezione Informazioni Generali campo codice richiesto";
                //     }
                // }

                if (errore == "") {
                    if ($("#fld_name").val() == undefined) {
                        errore = "Sezione Informazioni Generali campo ragione sociale richiesto";
                    }
                    else {
                        if ($("#fld_name").val() == "") {
                            errore = "Sezione Informazioni Generali campo ragione sociale richiesto";
                        }
                    }
                }

                if (errore == "") {
                    if ($("#idtipo_azienda").val() == undefined) {
                        errore = "Sezione Informazioni Generali campo tipo richiesto";
                    }
                    else {
                        if ($("#idtipo_azienda").val() == "") {
                            errore = "Sezione Informazioni Generali campo tipo richiesto";
                        }
                    }
                }


                if (errore == "") {

                    if ($("#tipo_chiusura_esercizio").val() == "I") {

                        if ($("#mese_chiusura_esercizio").val() == undefined) {
                            errore = "Sezione Altro campo mese richiesto";
                        }
                        else {
                            if ($("#mese_chiusura_esercizio").val() == "") {
                                errore = "Sezione Altro campo mese richiesto";
                            }
                        }


                    }

                }


                if (errore == "") {
                    if (that.azienda.partita_iva == "") {
                        errore = "Sezione Legale partita iva richiesta";
                    }
                }

                if (errore == "") {
                    if (that.azienda.partita_iva == null) {
                        errore = "Sezione Legale partita iva richiesta";
                    }
                }



                if (errore == "") {
                    if (that.azienda.codice_fiscale == "") {
                        errore = "Sezione Legale codice fiscale richiesta";
                    }
                }

                if (errore == "") {
                    if (that.azienda.codice_fiscale == null) {
                        errore = "Sezione Legale codice fiscale richiesta";
                    }
                }

            }



            if (that.$route.params.id > 0) {

                // if (that.azienda.code == null) {
                //     errore = "Sezione Informazioni Generali campo codice richiesto";
                // }
                // else {
                //     if (that.azienda.code == "") {
                //         errore = "Sezione Informazioni Generali campo codice richiesto";
                //     }
                // }

                if (errore == "") {

                    if (that.azienda.fld_name == null) {
                        errore = "Sezione Informazioni Generali campo ragione sociale richiesto";
                    }
                    else {
                        if (that.azienda.fld_name == "") {
                            errore = "Sezione Informazioni Generali campo ragione sociale richiesto";
                        }
                    }

                }

                if (errore == "") {

                    if (that.azienda.idtipo_azienda == null) {
                        errore = "Sezione Informazioni Generali campo tipo richiesto";
                    }
                    else {
                        if (that.azienda.idtipo_azienda == "") {
                            errore = "Sezione Informazioni Generali campo tipo richiesto";
                        }
                    }

                }

                if (errore == "") {

                    if (that.azienda.tipo_chiusura_esercizio == "I") {

                        if (that.azienda.mese_chiusura_esercizio == null) {
                            errore = "Sezione Altro campo mese richiesto";
                        }
                        else {
                            if (that.azienda.mese_chiusura_esercizio == "") {
                                errore = "Sezione Altro campo mese richiesto";
                            }
                        }


                    }


                }


                if (errore == "") {
                    if (that.azienda.partita_iva == "") {
                        errore = "Sezione Legale partita iva richiesta";
                    }
                }

                if (errore == "") {
                    if (that.azienda.partita_iva == null) {
                        errore = "Sezione Legale partita iva richiesta";
                    }
                }



                if (errore == "") {
                    if (that.azienda.codice_fiscale == "") {
                        errore = "Sezione Legale codice fiscale richiesta";
                    }
                }

                if (errore == "") {
                    if (that.azienda.codice_fiscale == null) {
                        errore = "Sezione Legale codice fiscale richiesta";
                    }
                }









            }


            if (errore == "") {


                console.log("ok");


                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }




        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        initInsertAzienda: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiaziende.initInsertAzienda(
                v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsertAzienda", res);

                this.tipo = res.data.tipo_azienda;

                this.settoriattivita = res.data.company_settori_attivita;

                this.gruppi = res.data.groups;

                this.mesi = res.data.month;

                this.proprietari = res.data.proprietari;

                this.commercialisti = res.data.commercialisti;

                this.referenti_agila = res.data.utenti;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },


        initEditAzienda: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiaziende.initEditAzienda(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initEditAzienda", res);

                this.tipo = res.data.tipo_azienda;

                this.settoriattivita = res.data.company_settori_attivita;

                this.gruppi = res.data.groups;

                this.mesi = res.data.month;

                this.proprietari = res.data.proprietari;

                this.commercialisti = res.data.commercialisti;

                this.referenti_agila = res.data.utenti;

                this.azienda = res.data.company[0];

                this.referenti = res.data.referenti;

                this.nomeFileLogo = res.data.nome_file_logo;

                this.showReferenti = true;


                if (this.azienda.idtipo_azienda != null) {

                    $("#itemTipo").addClass("item-has-value");

                }

                if (this.azienda.id_settore_attivita != null) {

                    $("#itemSettoreAttività").addClass("item-has-value");

                }

                if (this.azienda.sede_estera != null) {

                    $("#itemSedeEstera").addClass("item-has-value");

                }

                if (this.azienda.group_id != null) {

                    $("#itemGruppo").addClass("item-has-value");

                }

                if (this.azienda.is_ref_unico != null) {

                    $("#itemReferente").addClass("item-has-value");

                }

                if (this.azienda.tipo_chiusura_esercizio != null) {

                    $("#itemChiusuraEsercizio").addClass("item-has-value");

                }

                if (this.azienda.mese_chiusura_esercizio != null) {

                    $("#itemMese").addClass("item-has-value");

                }

                if (this.azienda.id_commercialista != null) {

                    $("#itemCommercialista").addClass("item-has-value");

                }

                if (this.azienda.ref_commerciale_int != null) {

                    $("#itemRefAgila").addClass("item-has-value");

                }

                if (this.azienda.forma_societaria != null) {

                    $("#itemFormaGiuridica").addClass("item-has-value");

                }

                if (this.azienda.associata_controllata != null) {

                    $("#itemControllataCollegata").addClass("item-has-value");

                }

                if (this.azienda.proprietario != null) {

                    $("#itemProprietario").addClass("item-has-value");

                }

                if (this.azienda.dimensione_azienda != null) {

                    $("#itemDimAzi").addClass("item-has-value");

                }



                console.log("FILE LOGO: ", this.nomeFileLogo);
                console.log("FILE LOGO: ", this.loadLogo);

                if (this.loadLogo == 1) {

                    this.loadLogo = 0;

                    if (this.nomeFileLogo != "") {


                        var txt_image = this.getUrlDomain("public/documents/" + this.nomeFileLogo);


                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 100);


                    }



                }






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },




        syncPianoOrario: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var id_rec = that.$route.params.id;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianoorario.getPianoOrario(
                id_rec,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPianoOrario", res);

                this.aziende = res.data.company;

                this.ore = res.data.ore;

                this.pianoorario = res.data.piano_orario;

                $("#itemCompany").addClass("item-has-value");

                if (this.pianoorario.lunedi != null) {

                    $("#itemLunedi").addClass("item-has-value");

                }

                if (this.pianoorario.martedi != null) {

                    $("#itemMartedi").addClass("item-has-value");

                }

                if (this.pianoorario.mercoledi != null) {

                    $("#itemMercoledi").addClass("item-has-value");

                }

                if (this.pianoorario.giovedi != null) {

                    $("#itemGiovedi").addClass("item-has-value");

                }

                if (this.pianoorario.venerdi != null) {

                    $("#itemVenerdi").addClass("item-has-value");

                }

                if (this.pianoorario.sabato != null) {

                    $("#itemSabato").addClass("item-has-value");

                }

                if (this.pianoorario.domenica != null) {

                    $("#itemDomenica").addClass("item-has-value");

                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },



        saveData: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var id_rec = that.$route.params.id;

            console.log(v_token);
            console.log(id_rec);

            if ($("#idtipo_azienda").val() != "") {
                if ($("#idtipo_azienda").val() != undefined) {
                    that.azienda.idtipo_azienda = $("#idtipo_azienda").val();
                }
            }

            if ($("#id_settore_attivita").val() != "") {
                if ($("#id_settore_attivita").val() != undefined) {
                    that.azienda.id_settore_attivita = $("#id_settore_attivita").val();
                }
            }

            if ($("#sede_estera").val() != "") {
                if ($("#sede_estera").val() != undefined) {
                    that.azienda.sede_estera = $("#sede_estera").val();
                }
            }

            if ($("#group_id").val() != "") {
                if ($("#group_id").val() != undefined) {
                    that.azienda.group_id = $("#group_id").val();
                }
            }


            if ($("#is_ref_unico").val() != "") {
                if ($("#is_ref_unico").val() != undefined) {
                    that.azienda.is_ref_unico = $("#is_ref_unico").val();
                }
            }



            if ($("#tipo_chiusura_esercizio").val() != "") {
                if ($("#tipo_chiusura_esercizio").val() != undefined) {
                    that.azienda.tipo_chiusura_esercizio = $("#tipo_chiusura_esercizio").val();
                }
            }

            if ($("#mese_chiusura_esercizio").val() != "") {
                if ($("#mese_chiusura_esercizio").val() != undefined) {
                    that.azienda.mese_chiusura_esercizio = $("#mese_chiusura_esercizio").val();
                }
            }

            if ($("#forma_societaria").val() != "") {
                if ($("#forma_societaria").val() != undefined) {
                    that.azienda.forma_societaria = $("#forma_societaria").val();
                }
            }

            if ($("#associata_controllata").val() != "") {
                if ($("#associata_controllata").val() != undefined) {
                    that.azienda.associata_controllata = $("#associata_controllata").val();
                }
            }

            if ($("#proprietario").val() != "") {
                if ($("#proprietario").val() != undefined) {
                    that.azienda.proprietario = $("#proprietario").val();
                }
            }

            if ($("#dimensione_azienda").val() != "") {
                if ($("#dimensione_azienda").val() != undefined) {
                    that.azienda.dimensione_azienda = $("#dimensione_azienda").val();
                }
            }

            if ($("#enable_projects").val() != "") {
                if ($("#enable_projects").val() != undefined) {
                    that.azienda.enable_projects = $("#enable_projects").val();
                }
            }

            if ($("#use_only_ebadge").val() != "") {
                if ($("#use_only_ebadge").val() != undefined) {
                    that.azienda.use_only_ebadge = $("#use_only_ebadge").val();
                }
            }

            if ($("#monthly_timesheet").val() != "") {
                if ($("#monthly_timesheet").val() != undefined) {
                    that.azienda.monthly_timesheet = $("#monthly_timesheet").val();
                }
            }

            if ($("#rich_ferie_permessi").val() != "") {
                if ($("#rich_ferie_permessi").val() != undefined) {
                    that.azienda.rich_ferie_permessi = $("#rich_ferie_permessi").val();
                }
            }

            if ($("#com_malattia").val() != "") {
                if ($("#com_malattia").val() != undefined) {
                    that.azienda.com_malattia = $("#com_malattia").val();
                }
            }

            if ($("#ebadge_regtype_qrcode").val() != "") {
                if ($("#ebadge_regtype_qrcode").val() != undefined) {
                    that.azienda.ebadge_regtype_qrcode = $("#ebadge_regtype_qrcode").val();
                }
            }

            if ($("#ebadge_regtype_manual").val() != "") {
                if ($("#ebadge_regtype_manual").val() != undefined) {
                    that.azienda.ebadge_regtype_manual = $("#ebadge_regtype_manual").val();
                }
            }

            if ($("#ebadge_regtype_reception").val() != "") {
                if ($("#ebadge_regtype_reception").val() != undefined) {
                    that.azienda.ebadge_regtype_reception = $("#ebadge_regtype_reception").val();
                }
            }

            if ($("#ebadge_regtype_reception_hardware").val() != "") {
                if ($("#ebadge_regtype_reception_hardware").val() != undefined) {
                    that.azienda.ebadge_regtype_reception_hardware = $("#ebadge_regtype_reception_hardware").val();
                }
            }

            if ($("#use_otp").val() != "") {
                if ($("#use_otp").val() != undefined) {
                    that.azienda.use_otp = $("#use_otp").val();
                }
            }


            if ($('#input-file-logo')[0] != undefined) {
                if ($("#input-file-logo")[0].files.length > 0) {
                    that.azienda.logo = $('#input-file-logo')[0].files[0];
                }
            }


            console.log("AZIENDA: ", that.azienda);


            if (id_rec == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apiaziende.validateVATCFAzienda(
                    id_rec,
                    that.azienda.partita_iva,
                    that.azienda.codice_fiscale,
                    v_token
                ).then(async (res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from validateVATCFAzienda", res);


                    if (res.data.Result == "OK") {


                        //console.log("ok");

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response2 = await apiaziende.insertAzienda(
                            that.azienda,
                            v_token
                        ).then((res2) => {

                            console.log(response2);

                            that.$root.$children[0].showProgress = false;
                            console.log("res from insertAzienda", res2);


                            that.$swal({
                                icon: "success",
                                text: "Dati salvati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                router.push({
                                    path: "/companyAgila"
                                });

                            }, 200);



                        }).catch(err2 => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err2.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile salvare i dati");
                            console.log("response", response);

                        }


                        );





                    }
                    else {


                        that.$swal({
                            icon: "error",
                            text: res.data.Errore,
                            showConfirmButton: false,
                            timer: 2000
                        });



                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }



            if (id_rec > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                console.log("ATTACH REMOVE: ", that.attachRemove);

                var v_attach_remove = 0;
                if (that.attachRemove == true) {
                    v_attach_remove = 1;
                }

                var response2 = await apiaziende.validateVATCFAzienda(
                    id_rec,
                    that.azienda.partita_iva,
                    that.azienda.codice_fiscale,
                    v_token
                ).then(async (res) => {

                    console.log(response2);

                    that.$root.$children[0].showProgress = false;
                    console.log("res from validateVATCFAzienda", res);


                    if (res.data.Result == "OK") {

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response3 = await apiaziende.updateAzienda(
                            that.azienda,
                            id_rec,
                            v_token,
                            v_attach_remove
                        ).then((res2) => {

                            console.log(response3);

                            that.$root.$children[0].showProgress = false;
                            console.log("res from updateAzienda", res2);

                            that.attachRemove = false;

                            that.$swal({
                                icon: "success",
                                text: "Dati salvati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                router.push({
                                    path: "/companyAgila"
                                });

                            }, 200);



                        }).catch(err2 => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err2.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile salvare i dati");
                            console.log("response", response);

                        }


                        );





                    }
                    else {


                        that.$swal({
                            icon: "error",
                            text: res.data.Errore,
                            showConfirmButton: false,
                            timer: 2000
                        });



                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }








        },



        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamento(
                that.$route.params.id, v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamento", res);

                try {

                    this.pagamento = res.data.Result;

                    this.importoRimanente = res.data.Result.importo_rimanente;

                    if (res.data.Result.is_child_pag == "N") // pagamento master
                    {

                        this.pagamento.importo_pagato = null;

                        var today = new Date();

                        var sDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, 0) + "-" + today.getDate().toString().padStart(2, 0);

                        console.log("DATE: ", sDate);

                        this.pagamento.data_pagamento = sDate; // default data corrente

                    }

                    if (res.data.Result.is_child_pag == "S") // pagamento child
                    {

                        this.disField = true;

                    }

                    if (res.data.Result.importo != null) {

                        this.$root.$children[0].setFieldActive("#d_importo");

                    }

                    if (res.data.Result.importo_pagato != null) {

                        this.$root.$children[0].setFieldActive("#d_pagato");

                    }

                    if (res.data.Result.importo_rimanente != null) {

                        this.$root.$children[0].setFieldActive("#d_importo_rimanente");

                    }

                    this.valoreDaPagare = res.data.Result.importo;

                    this.valoreRimanente = res.data.Result.importo_rimanente;

                    this.disFieldRim = true;

                    var allegato_salvato = res.data.Result.allegato_salvato;

                    if (allegato_salvato) {

                        var elements = allegato_salvato.split(".");

                        console.log("ELEMENTS: ", elements);

                        var txt_image = "";
                        if (elements[1] == "pdf") {

                            txt_image = this.getUrlDomain("public/documents/pdf_extension.png");

                        } else {

                            txt_image = this.getUrlDomain("public/documents/" + allegato_salvato);

                        }

                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 100);

                    }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.editCompanyAgilaAlertMsg .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}



.editCompanyAgilaAlertMsg {
    width: 1000px !important;
    /* padding: 0px!important;
    z-index: 3!important; */
}


.v-form {
    margin-top: 41px;
}

.editCompanyAgilaAlertMsg .mainTitle {
    padding: 20px !important;
    position: fixed;
    /* min-width: 500px; */
    z-index: 1;
    top: 65px;
    /* border: 1px solid black; */
    background-color: white;
    color: black;
    font-weight: bold;
    /* padding: 4px; */
    font-size: 10px;
    min-width: 100vw;
    border-color: #da6a2d
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}


.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetAziendeClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetDipartimento {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetUtentiAssociati {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetPianoOrario {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetGruppi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemAziendaCliente {
    margin-left: 15px;
}

.itemCliente {
    margin-left: 15px;
}

.itemDipartimento {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.itemUtenteAssociato {
    margin-left: 15px;
}


.itemPianoOrarioCodice {
    margin-left: 15px;
}

.itemPianoOrarioDescrizione {
    margin-left: 15px;
}

.itemGruppo {
    margin-left: 15px;
}


.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.editCompanyAgilaAlertMsg .dropify-wrapper {
    height: 187px !important;
    width: 187px !important;
    margin-bottom: 20px;
    margin-left: 1px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}


.editCompanyAgilaAlertMsg .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.editCompanyAgilaAlertMsg .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.editCompanyAgilaAlertMsg ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}

.cl-copy-element {
    width: 20px;
    cursor: pointer;
}

.cont_piano_orario #myTablePianoOrario {
    margin-right: 3px;
}

.cont_piano_orario table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_piano_orario table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_piano_orario th {
    padding-left: 5px !important;
}

.cont_piano_orario ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_piano_orario .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}

.cont_piano_orario #myTablePianoOrario_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
}

.cont_piano_orario div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_piano_orario #myTablePianoOrario_wrapper {
    padding-top: 0px;
}

.text_title {
    background-color: #f5cf2e;
    color: white;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 82vw
}

.cont_piano_orario .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}


.first_column_piano_orario {
    width: 4%;
    text-align: left;
}

.second_column_piano_orario {
    width: 32%;
    text-align: left;
    font-weight: bold;
    font-size: 10px;
}

.third_column_piano_orario {
    width: 32%;
    text-align: left;
    margin-left: -6px;
    font-weight: bold;
    font-size: 10px;
}

.fourth_column_piano_orario {
    margin-left: -5px;
    font-weight: bold;
    font-size: 10px;
}


.itemNRow {
    margin-left: -33px;
}

.itemRef {
    margin-left: -87px;
}

.itemAzi {
    margin-left: -17px;
}

@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .editCompanyAgilaAlertMsg .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

    .cont_piano_orario .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_piano_orario #myTablePianoOrario {
        /* margin-top:54px; */
    }

    .cont_piano_orario #myTablePianoOrario_filter {
        top: 56px;
        position: fixed;

    }


    .first_column_piano_orario {
        width: 19%;
        text-align: left;
    }

    .second_column_piano_orario {
        width: 28%;
        text-align: left;
        font-weight: bold;
        font-size: 10px;
    }

    .third_column_piano_orario {
        width: 28%;
        text-align: left;
        margin-left: -6px;
        font-weight: bold;
        font-size: 10px;
    }

    .fourth_column_piano_orario {
        margin-left: -5px;
        font-weight: bold;
        font-size: 10px;
    }




}


@media screen and (max-width: 1024px) {


    .cont_piano_orario #myTablePianoOrario {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_piano_orario #myTablePianoOrario_filter {
        top: 56px;
        position: fixed;

    }

    .cont_piano_orario .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_piano_orario #myTablePianoOrario_wrapper {
        min-width: initial;
        min-width: 95vw !important;
        max-width: 95vw !important;
        width: 95vw !important;
        overflow-x: hidden !important;
    }

    .cont_piano_orario .cl_num {
        margin-left: 16px !important;
    }





}
</style>