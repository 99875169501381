var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-center d-flex align-center justify-center",staticStyle:{"max-width":"300px"},attrs:{"fluid":"","fill-height":""}},[_c('v-row',[(_vm.isEmployee == 1 && _vm.viewStoricoBase == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/messages"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/storico-alertmsg.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("History")])]}}],null,false,3064404025)},[_c('span',[_vm._v("History")])])],1):_vm._e(),(_vm.isPwdChangedOnce > 0 && _vm.viewMsgBase == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/messaggioBase"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/messaggio-base-alertmsg.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Basic Message")])]}}],null,false,4078427136)},[_c('span',[_vm._v("Basic Message")])])],1):_vm._e(),(_vm.isPwdChangedOnce > 0 && _vm.viewMsgRisorse == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/sendMessageTitleMessage"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/messaggio-risorse-alertmsg.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("User Messages")])]}}],null,false,3117273992)},[_c('span',[_vm._v("User Messages")])])],1):_vm._e(),(_vm.isSU == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/documentsShared"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/cartella-freccia-alertmsg.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Documents")])]}}],null,false,1406139969)},[_c('span',[_vm._v("Documents")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }