<template>
    <v-container id="conf_settings_firma" class="w-container send_message_doc_cond" style="padding-top:59px">


        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                    background-color: white;
                    color: black;
                    font-weight: bold;
                    padding: 4px;
                    font-size: 10px;
                    min-width:100vw;
                    border-color:#da6a2d">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>


                <v-col cols="12" sm="12" md="12">
                    <v-text-field id='oggetto' v-model="oggetto" label="Title"></v-text-field>
                </v-col>


            </v-row>

            <v-row>

                <v-col cols="12" sm="12" md="12">
                    <v-textarea id='body' v-model="testo" outlined name="input-7-4" label="Message" models="body">
                    </v-textarea>
                </v-col>

            </v-row>



        </v-form>








    </v-container>
</template>

<script>

import apisettings from "../utils/settings/apisettings";
import router from ".././router";

import {
    bus
} from "../main";


export default ({
    mounted() {

        this.$root.$children[0].checkLogout();


        this.$root.$children[0].verifyTrialPeriod();


        /*eslint-disable no-undef*/


        var pointerVue = this;

        this.setupButtons();

    

        this.titolo = "Send Message";

     

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":


                    this.validate();

                    break;

                case "btn_undo":

                    this.undo();

                    break;

                case "btn_back":

                    router.push({
                        path: "/utentiSendDocCond"
                    });

                    break;



                default:
                    break;
            }
        });

        $(document).ready(function () {

            $(".tipo").on("ionChange", function (opt) {
                console.log(opt);

                console.log("VAL: ", $(this).val());


                if ($(this).val() == "Testo") {

                    pointerVue.viewImage = false;
                    pointerVue.viewSign = false;
                    pointerVue.viewText = true;

                    $(".divFirma").hide();

                }

                if ($(this).val() == "Firma") {

                    pointerVue.viewSign = true;
                    pointerVue.viewImage = false;
                    pointerVue.viewText = false;

                    $(".divFirma").show();

                    pointerVue.$refs.signaturePad.openSignaturePad();
                    pointerVue.$refs.signaturePad.clearSignature();

                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'));
                    }, 300);

                }

                if ($(this).val() == "Immagine") {

                    pointerVue.viewImage = true;
                    pointerVue.viewSign = false;
                    pointerVue.viewText = false;

                    $(".divFirma").hide();

                    setTimeout(() => {

                        pointerVue.formatDropify();

                    }, 100);

                }


            });


        });



        // setTimeout(() => {

        //     pointerVue.sheetSignature = true;

        // }, 600);


        pointerVue.formatDropify();


    },


    computed: {

        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },


    data: () => ({

        oggetto: "",

        hasFile: false,

        valueReadonly: false,

        currentFile: null,

        tipi: [{ id: "Firma", name: "Firma" }, { id: "Immagine", name: "Immagine" }],

        isDefault: false,

        sheetSignature: false,

        totSignatures: 0,
        totImages: 0,

        viewText: false,
        viewImage: false,
        viewSign: false,

        coordXFirma: null,
        coordYFirma: null,

        firma: {},

        fieldDis: false,

        showRow: true,
        showRow2: true,

        //  currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Firma",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni: { is_notify_enable: "N" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,



    }),


    methods: {

        getUrlFolder: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },


        formatDropify: function () {

            var that = this;

            var oggDrop = $('.dropify').dropify({
                messages: {
                    default: 'Drag',
                    replace: /* s */ '',
                    remove: 'Rimuovi',
                    error: 'File troppo grande'
                }
            });

            $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
            $(".dropify-render").append("<img>");

            //console.log(oggDrop);

            oggDrop.on('dropify.afterClear', function (event, element) {

                console.log(event, element);

                that.hasFile = false;

            });

        },


        undo() {
            this.$refs.signaturePad.undoSignature();
        },

        saveData: function () {

            var that = this;

            var title = $("#oggetto").val();
            var body = $("#body").val();

            var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");
            var v_id_users_doc_send = window.$cookies.get("id_users_doc_send");
            var v_id_docs_sel = window.$cookies.get("sel_id_doc_cond");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


          //  window.$cookies.set("reload_messages", 1, "9y");

            try {
                var response = this.$api.insertMultiMessageDocCond(
                    token,
                    username,
                    title,
                    body,
                    0,
                    v_id_docs_sel,
                    v_id_users_doc_send,
                    1
                );

                console.log(response);

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;

                this.$swal({
                    icon: "success",
                    text: "Your message has been sent successfully",
                    showConfirmButton: false,
                    timer: 4000
                });



                setTimeout(() => {

              
                    router.push({
                        path: "/messages"
                    });

                }, 4000);






            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }






        },

        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Send",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/invio-alertmsg.png",
                    title: "Send",
                    width: 30

                },

                // {
                //     text: "Pulisci",
                //     icon: "mdi-content-save",
                //     link: "/timesheet",
                //     id: "btn_undo",
                //     disabled: false,
                //     image: "https://app.emtool.eu/public/_lib/img/undo2.png",
                //     class: "inverted",
                //     title: "Pulisci"

                // },


                {
                    text: "Pulisci",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/back-alertmsg.png",
                    width: 35

                }



                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var errore = "";

            var that = this;


            if ($("#oggetto").val() == "") {
                errore = "Title required";
            }


            if (errore == "") {


                if ($("#body").val() == "") {
                    errore = "Message required";
                }


            }


            if (errore == "") {

                that.saveData();

            } else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },


        syncDefaultCheckbox: async function () {

            var that = this;

            //     var v_token = window.$cookies.get("token");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            // var response = await apifirme.getDefaultCheckbox(
            //     v_token,
            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from getDefaultCheckbox", res);

            //     that.totSignatures = res.data.TotSignatures;
            //     that.totImages = res.data.TotImages;


            // }).catch(err => {
            //     that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     var msg = err.response.data.Error;

            //     that.$swal({
            //         icon: "error",
            //         text: msg,
            //         showConfirmButton: false,
            //         timer: 8000
            //     });
            //     console.log("ERrori", "Non è stato possibile salvare i dati");
            //     console.log("response", response);

            // }

            // );



        },


        syncSign: async function () {

            //  var that = this;

            //var signaturePad = this.$refs.signaturePad;

            //  var v_token = window.$cookies.get("token");



            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            // var response = await apifirme.getSign(
            //     v_token,
            //     that.$route.params.id
            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from getSign", res);

            //     that.firma = res.data.signature;



            //     console.log("DEFAULT: ", res.data.signature.is_default);

            //     if (res.data.signature.is_default == 1) {

            //         console.log("OK");

            //         that.valueReadonly = true;

            //         setTimeout(() => {

            //             $(".checkDefault").prop("checked", true);

            //         }, 200);



            //     }


            //     if (res.data.signature.content_signature != null) {

            //         //console.log("CONTENT SIGN: ", res.data.signature.content_signature);

            //         var content = res.data.signature.content_signature;

            //         console.log("CONTENT SIGN: ", content);

            //         setTimeout(() => {
            //             $("canvas").hide();
            //             $("canvas").show();


            //             setTimeout(() => {

            //                 var canv = document.getElementsByTagName("canvas")[0];
            //                 var ctx = canv.getContext("2d");

            //                 var myImage = new Image();
            //                 //myImage.width = 200;

            //                 //console.log("myImage: ", myImage);

            //                 myImage.src = content;
            //                 //ctx.drawImage(myImage, 0, 0, 960, 200);

            //                 ctx.drawImage(myImage, 0, 0, 480, 100);

            //                 // window.dispatchEvent(new Event('resize'));


            //                 //that.$refs.signaturePad.fromData(content);

            //             }, 500);

            //         }, 100);


            //     }



            //     if (res.data.signature.file_immagine != null) {

            //         var tipo_immagine = res.data.signature.tipo_immagine;

            //         console.log("TIPO IMMAGINE: ", tipo_immagine);


            //         that.hasFile = true;

            //         var txt_image = "";

            //         var nome_file = res.data.signature.file_immagine;

            //         var elements = nome_file.split(".");

            //         var extension = elements[elements.length - 1];

            //         console.log("EXT: ", extension);




            //         if (tipo_immagine == "documento") {

            //             txt_image = extension;
            //         }

            //         if (tipo_immagine == "immagine") {

            //             txt_image = this.getUrlFolder("public/docsign/" + res.data.signature.file_immagine);
            //         }


            //         console.log("ALLEGATO: ", txt_image);

            //         setTimeout(() => {

            //             if (tipo_immagine == "immagine") {

            //                 $(".dropify-render img").attr("src", txt_image);
            //                 $(".dropify-wrapper").addClass("has-preview");
            //                 $(".dropify-preview").show("slow");

            //             }

            //             if (tipo_immagine == "documento") {

            //                 $('.dropify-render').append("<i class='dropify-font-file'></i>");
            //                 $('.dropify-render').append("<span class='dropify-extension'>" + txt_image + "</span>");

            //                 // dropify-font-file
            //                 $(".dropify-wrapper").addClass("has-preview");
            //                 $(".dropify-preview").show("slow");

            //             }

            //             // if (isImage) {
            //             //     $(".dropify-render img").attr("src", txt_image);
            //             //     $(".dropify-wrapper").addClass("has-preview");
            //             //     $(".dropify-preview").show("slow");

            //             // }
            //             // else {

            //             //     $('.dropify-render').append("<i class='dropify-font-file'></i>");
            //             //     $('.dropify-render').append("<span class='dropify-extension'>" + txt_image + "</span>");

            //             //     // dropify-font-file
            //             //     $(".dropify-wrapper").addClass("has-preview");
            //             //     $(".dropify-preview").show("slow");

            //             // }



            //         }, 200);


            //     }







            // }).catch(err => {
            //     //that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     console.log(response);

            //     //var msg = err.response.data.Error;

            //     // that.$swal({
            //     //     icon: "error",
            //     //     text: msg,
            //     //     showConfirmButton: false,
            //     //     timer: 8000
            //     // });
            //     // console.log("ERrori", "Non è stato possibile salvare i dati");
            //     // console.log("response", response);

            // }

            // );



        },


        syncConfInvioMail: async function () {

            // var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisettings.getSettings(
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getSettings", res);

                try {

                    that.impostazioni = res.data.settings;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }



    }

})
</script>

<style>
#conf_settings_firma .v-label {
    font-size: 1.2em !important;
}

#conf_settings_firma .v-input {
    font-size: 1em !important;
    padding: 10px !important;
}



.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}


.contSignature {
    margin: 0 auto;
}

.contSignature canvas {

    max-width: 98vw;
    border: 1px solid hsla(0, 0%, 50.2%, 0.33);
    padding-bottom: 10px;
    padding-top: 10px;
}

.buttonsPdf {
    margin-bottom: 150px;
    padding-top: 10px;
}

.buttonsPdf button {
    margin-right: 6px;
    margin-left: 6px;
}

/* .v-bottom-sheet.v-dialog {
    align-self: flex-start !important;
    
    margin-top: 69px;
} */

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.send_message_doc_cond .dropify-wrapper {
    height: 236px !important;
    width: 96% !important;
    margin-bottom: 20px!important;
    margin-left: 10px!important;
}

.files label {
    margin-left: 0px !important;
}
</style>
