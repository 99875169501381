<template>
    <v-container class="w-container-view-pdf-alertmsg v-overflow-view-pdf-alertmsg edit_utente_alertmsg"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">



        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;">


                <div class="mainTitle" style="
                        background-color: white!important;
                        color: black;
                        font-weight: bold;
                        padding: 4px;
                        font-size: 10px;                
                        min-width:100vw;
                        border-color:#da6a2d;display:flex">
                    {{ titolo }}
                    <div style="display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    width: 93%;">
                        <div style="font-size:14px"></div>
                    </div>
                </div>


            </v-col>

        </v-row>



        <v-form style="" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12" style="display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center">

                    <div style="width: 94%;height: 75vh;overflow-y: auto!important;overflow-x: auto!important;">

                        <div id="container-view-pdf-alertmsg"></div>


                    </div>


                </v-col>

            </v-row>



        </v-form>


        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">

                                <v-img alt="" src="@/assets/btn_cancel3_alertmsg.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"
                                    id="fldTextSearchAzi">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="" src="@/assets/aziende-alertmsg.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>


        <div class="contpopupsignatureviewfile" style="display:none">

            <template>
                <modal name="popupSignatureViewFile" :clickToClose="false" :width="600" :height="322">

                    <v-row style="margin-bottom: 0px;text-align: center;padding-top:10px">

                        <v-col cols="12" md="12" class="titleSignature" style="font-weight: bold;">
                            FIRMA PER ACCETTAZIONE
                        </v-col>

                    </v-row>


                    <v-row style="margin-top: 0px;">

                        <v-col cols="12" md="12" style="text-align: center;">

                            <VueSignaturePad width="550px" height="200px" ref="signaturePad" class="contSignature" />

                        </v-col>

                    </v-row>



                    <v-row style="padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="padding-top: 0px!important;">

                            <div style="display:flex;justify-content:center">



                                <v-img alt="" class="btn-delete-signed" contain src="@/assets/icon_delete_alertmsg.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Pulisci" @click="btnResetSignatureViewFile" />


                                <v-img alt="" class="btn-confirm-signed" contain
                                    src="@/assets/btn_confirm3_alertmsg.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmSignatureViewFile" />





                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupotplogin" style="display:none">

            <template>
                <modal name="popupOtpLogin" :clickToClose="false" :width="262" :height="221">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;font-size:12px;text-align: center;">

                            Codice OTP inviatoLe via SMS

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="text" v-model="valueOtpLogin" label="" id="fldValueOtpLoginPrjweb"
                                onkeydown="if (!/^[0-9]$/.test(event.key) && event.key !== 'Backspace') event.preventDefault();">
                            </v-text-field>



                        </v-col>

                    </v-row>

                    <v-row v-if="showResend">

                        <v-col cols="12" md="12" style="display: flex;
justify-content: center;
padding-top: 0px!important;
margin-top: 0px!important;
font-size:12px">

                            <div style="font-weight: bold;
cursor: pointer;" class="clReinviaOtp">Reinvia</div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">


                                <v-img alt="" class="btn-confirm-otp-login" contain
                                    src="@/assets/btn_confirm3_alertmsg.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmOtpLogin" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>






    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apimessaggi from "../utils/messaggi/apimessaggi";

import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";

import axios from 'axios';



import VueSignaturePad from 'vue-signature-pad';

Vue.use(VueSignaturePad);


export default ({
    mounted() {

        /*eslint-disable no-undef*/


        console.log("CURRENT ITEM: ", window.currentItemRow);





        this.$root.$children[0].checkLogout();


        var pointerVue = this;

        //window.$cookies.get("aziende_user")

        this.setupButtons();


        setTimeout(() => {


            this.viewAttachment();

        }, 100);



        if (this.$route.params.id > 0) {

            this.titolo = "Document";

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    //this.validate();

                    $(".contpopupsignatureviewfile").show();

                    pointerVue.$modal.show('popupSignatureViewFile');

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/messages"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '.clReinviaOtp', function (event) {
                event.preventDefault();

                pointerVue.showResend = false;

                pointerVue.$modal.hide('popupOtpLogin');


                pointerVue.getDataForOtpResend();


            });



            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

                setTimeout(() => {

                    $("#fldTextSearchAzi").focus();

                }, 500);


            });

        });

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        showResend: false,

        valueOtpLogin: "",

        pdfUrl: "",

        textSearch: "",

        valoresino: [{ id: "S", name: "Si" }, { id: "N", name: "No" }],

        changePsw: false,

        titolo: "Document",
        currDipendenteObj: {},

        gruppi_checked: [],

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: { is_admin_company: "N", active: "Y" },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],


        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {


        sendMailDocSigned: async function () {



            var that = this;

            var v_token = window.$cookies.get("token");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.sendMailDocSigned(
                v_token,
                window.currentItemRow.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendMailDocSigned", res);

                if (res.data.Result == "OK") {

                    console.log("ok");

                    setTimeout(() => {

                        that.$swal({
                            icon: "success",
                            text: "Documento firmato correttamente, una e-mail Le è stata inviata",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        router.push({
                            path: "/messages"
                        });


                    }, 200);



                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },




        signedDocumentWithPython: async function (v_input_pdf_path, v_output_pdf_path, v_image_path, v_testo_sms, v_testo_sms2, v_footer_option) {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


            const formData = new FormData();
            formData.append('input_pdf_path', v_input_pdf_path);
            formData.append('output_pdf_path', v_output_pdf_path);
            formData.append('image_path', v_image_path);
            formData.append('testo_sms', v_testo_sms);
            formData.append('footer_option', v_footer_option);
            formData.append('testo_sms2', v_testo_sms2);

            //var v_url = "http://127.0.0.1:8000/edit_pdf/";
            var v_url = "https://filestransfer.it:8000/edit_pdf/";

            try {


                const response = await axios.post(v_url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                console.log("res from edit-pdf", response);


                // this.message = response.data.detail;

                that.$root.$children[0].showProgress = false;

                // that.$swal({
                //     icon: "success",
                //     text: "Documento firmato correttamente, una e-mail Le è stata inviata",
                //     showConfirmButton: false,
                //     timer: 2000
                // });

                that.sendMailDocSigned();

            } catch (error) {

                that.$root.$children[0].showProgress = false;

                that.$swal({
                    icon: "error",
                    text: "Errore durante la firma del documento",
                    showConfirmButton: false,
                    timer: 3000
                });

            }


        },



        getValueForOtp: async function (v_username, v_name_product, v_mobile, v_prefix_mobile) {



            var that = this;

            that.$modal.hide('popupSignatureViewFile');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getValueForOtp(
                v_username,
                v_name_product,
                v_mobile,
                v_prefix_mobile
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getValueForOtp", res);

                if (res.data.Result == "OK") {

                    console.log("ok");

                    window.myVal = res.data.ValReturn;


                    setTimeout(() => {

                        that.showResend = true;

                        that.valueOtpLogin = "";

                        $(".contpopupotplogin").show();

                        that.$modal.show('popupOtpLogin');



                        setTimeout(() => {

                            $("#fldValueOtpLoginPrjweb").focus();

                        }, 300);

                    }, 200);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        getDataForOtpResend: async function () {

            var that = this;

         
            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getDataForOtp(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDataForOtp", res);

                if (res.data.Result == "OK") {

                    console.log("ok");

                    that.getValueForOtp(res.data.Username, "emt", res.data.Mobile, res.data.Prefix);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        getDataForOtp: async function () {

            var that = this;

            // recupero immagine firma fatta
            const { isEmpty, data } = that.$refs.signaturePad.saveSignature();
            window.dataSigned = data;

            console.log(isEmpty);




            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getDataForOtp(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDataForOtp", res);

                if (res.data.Result == "OK") {

                    console.log("ok");

                    that.getValueForOtp(res.data.Username, "emt", res.data.Mobile, res.data.Prefix);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        saveSignedDocument: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.saveSignedDocument(
                window.currentItemRow.id,
                window.dataSigned,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveSignedDocument", res);

                if (res.data.Result == "OK") {

                    that.signedDocumentWithPython(res.data.PathInputFilePdf,
                        res.data.PathOutputFilePdf,
                        res.data.PathImageSign,
                        res.data.TextSms,
                        res.data.TextSms2,
                        1);

                    // that.$swal({
                    //     icon: "success",
                    //     text: "Documento firmato correttamente, una e-mail Le è stata inviata",
                    //     showConfirmButton: false,
                    //     timer: 2000
                    // });

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

        btnConfirmOtpLogin: function () {


            if (this.valueOtpLogin != "") {

                console.log("OTP INP: ", this.valueOtpLogin);
                console.log("ENC OTP INP: ", btoa(this.valueOtpLogin));

                if (window.myVal == btoa(this.valueOtpLogin)) {

                    this.$modal.hide('popupOtpLogin');


                    //this.loginAction2(this.input.username);

                    this.saveSignedDocument();


                }
                else {


                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        this.valueOtpLogin = "";
                        $("#fldValueOtpLoginPrjweb").focus();

                    }, 2500);


                }



            }
            else {


                $("#fldValueOtpLoginPrjweb").focus();

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },


        btnResetSignatureViewFile: function () {

            this.$refs.signaturePad.undoSignature();

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },



        btnConfirmSignatureViewFile: function () {

            var that = this;

            var errore = "";

            if (that.$refs.signaturePad.isEmpty()) {
                errore = "La firma è richiesta";
            }

            if (errore == "") {

                console.log("ok");

                that.getDataForOtp();



            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }



        },



        async loadPdf() {

            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

            const pdfContainer = document.getElementById('container-view-pdf-alertmsg');
            pdfContainer.innerHTML = '';

            try {
                const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const viewport = page.getViewport({ scale: 1.5 });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };

                    await page.render(renderContext).promise;

                    pdfContainer.appendChild(canvas);
                }
            } catch (error) {
                console.error('Error loading PDF: ', error);
            }
        },


        viewAttachment: async function () {

            var that = this;

            var path_file = "";



            if (window.currentItemRow.doc_cond == 0) {

               // path_file = "s3/aziende/LC-SERVICE SRL/Products/Alertmsg/" + window.currentItemRow.attachment;

               path_file = "s3/aziende/" + window.currentItemRow.val_cmp_dest + "/Products/Alertmsg/" + window.currentItemRow.attachment;

            }

            if (window.currentItemRow.doc_cond == 1) {

                path_file = "s3/aziende/LC-SERVICE SRL/Products/Alertmsg/Condivisi/" + window.currentItemRow.attachment;

            }

            console.log("PATH FILE DOWN: ", path_file);

            var v_token = window.$cookies.get("token");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getAttachment(
                path_file,
                v_token,
                window.currentItemRow.attachment,
                window.currentItemRow.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAttachment", res);

                setTimeout(() => {


                    // $(".contpopupviewfilepdf").show();

                    // that.$modal.show('popupViewFilePdf');

                    that.pdfUrl = res.data.PathReturn;

                    setTimeout(() => {


                        that.loadPdf();



                    }, 200);



                }, 100);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.utente.idcompany_logo = azienda.companyid;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [

                    {
                        text: "Back",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_back",
                        disabled: false,
                        image: this.getUrlDomain("public/_lib/img/back-alertmsg.png"),
                        title: "Back",
                        width: 30

                    },





                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            //console.log("UTENTE 1: ", that.utente);

            //console.log("COMPANYID: ", $("#companyid").val());

            var res = that.$refs.form.validate();
            console.log(res);
            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {

                var errore = "";

                //console.log("GRUPPI: ", that.gruppi);

                if ($("#companyid").val() == "") {
                    errore = "Specificare l'azienda";
                }

                if (that.utente.is_su != "S") {

                    if (errore == "") {

                        var grpChecked = 0;

                        for (var i = 0; i < that.gruppi.length; i++) {

                            if ((that.gruppi[i] !== undefined) && (that.gruppi[i].checked !== undefined)) {

                                if (that.gruppi[i].checked != null) {
                                    grpChecked = grpChecked + 1;
                                }

                            }

                        }

                        console.log("GTP CHCK: ", grpChecked);

                        if (grpChecked == 0) {
                            errore = "Per favore specificare i gruppi";
                        }
                    }


                }


                if (errore == "") {

                    that.utente.idcompany_logo = $("#companyid").val();

                    that.saveData();

                } else {

                    that.$swal({
                        icon: "error",
                        text: errore,
                        showConfirmButton: false,
                        timer: 2000
                    });

                }



            }
        },

        saveData: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.utente.is_admin_company = $(".is_admin_company").val();

            that.utente.name = that.utente.nome_utente + " " + that.utente.cognome_utente;

            console.log("UTENTE 2: ", that.utente);

            var response = null;

            if (that.$route.params.id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiusers.insertUtente(
                    that.utente,
                    that.gruppi,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertUtente", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/users"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

            if (that.$route.params.id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiusers.updateUtente(
                    that.utente,
                    that.gruppi,
                    v_token,
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateUtente", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/users"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    // this.utente.nome = res.data.curr_user.nome_utente;
                    // this.utente.cognome = res.data.curr_user.cognome_utente;


                    if (res.data.curr_user.models != null) {

                        var grp_selected = res.data.curr_user.models.split(",");

                        console.log("GRP SEL: ", grp_selected);

                        if (grp_selected.length > 0) {

                            for (var i = 0; i < this.gruppi.length; i++) {

                                if (this.gruppi[i] !== undefined) {

                                    for (var x = 0; x < grp_selected.length; x++) {

                                        console.log("GRP SEL 2: ", grp_selected[x]);

                                        if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                            this.gruppi[i].checked = this.gruppi[i].id;

                                        }

                                    }

                                }

                            }

                        }


                    }


                    $("#itemAzienda").addClass("item-has-value");

                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token,
                "alertmsg"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createUtenteApi", res);


                try {

                    that.aziende = res.data.aziende;

                    that.prefissi = res.data.prefix;

                    that.gruppi = res.data.models;


                    setTimeout(() => {

                        //that.utente.idcompany_logo = window.$cookies.get("sel_filter_azienda_utenti");
                        that.utente.idcompany_logo = window.$cookies.get("sel_filter_azienda_utenti");

                        $(".companyid").val(window.$cookies.get("sel_filter_azienda_utenti"));

                    }, 200);


                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.v-overflow-view-pdf-alertmsg {
    overflow-y: auto !important;
    padding-bottom: 115px !important;
    max-height: 100vh !important;
    overflow-x: auto !important;
}


.contSignature {
    margin: 0 auto;
}

.contSignature canvas {

    max-width: 98vw;
    border: 1px solid black;
    padding-bottom: 10px;
    padding-top: 10px;
}



canvas {
    border: 1px solid #c7c4a9;
    margin-top: 10px;
}

#container-view-pdf-alertmsg {
    display: flex;
    flex-direction: column;
}



.w-container-view-pdf-alertmsg .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}

.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-view-pdf-alertmsg {
    width: 1400px !important;
    min-width: 1400px !important;
    max-width: 1400px !important;
    padding: 0px;
    z-index: 3;
}


.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.edit_utente_alertmsg .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}



.edit_utente_alertmsg .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.edit_utente_alertmsg .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.edit_utente_alertmsg ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}



.edit_utente_alertmsg .cl-checkbox i {
    font-size: 13px !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
