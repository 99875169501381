<template>
  <v-container style="padding-top: 59px; background-color: rgba(255, 255, 255, 0.5);">
    <v-row>
      <v-col class="mainCont" style="min-width: 100vw; position: fixed; left: 0; padding-left: 0px;">
        <div class="mainTitleConfSign"
          style="background-color: white!important; color: black; font-weight: bold; padding: 4px; font-size: 10px; min-width: 100vw; border-color: #da6a2d; display: flex;">
          {{ titolo }}
        </div>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" md="12" style="display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center">
          <div id="pdf-container" ref="pdfContainer"
            style="width: 85%; height: 90vh; overflow-y: auto; position: relative;">
            <div v-for="pageNumber in totalPages" :key="pageNumber" :ref="'pdfPage-' + pageNumber"
              style="position: relative;">
              <canvas :id="'pdf-canvas-' + pageNumber" :width="pdfWidth" :height="pdfHeight"
                style="border: 1px solid #000; margin-bottom: 20px;" @click="handleCanvasClick($event, pageNumber)">
              </canvas>
            </div>
            <!-- Riquadri di evidenziazione per le coordinate salvate -->
            <div v-for="(coord, index) in coordinates" :key="`highlight-${index}`" class="highlight-box"
              :style="{ left: `${coord.canvasX}px`, top: `${coord.canvasY}px`, position: 'absolute' }"
              @click.stop="removeHighlight(index)">
              <span class="close-box">✖</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

export default {
  data() {
    return {
      pdfUrl: "http://emtool.local/public/documents/alertmsg/tmp/20230320_lc_service_srl_mauro_cipolla_cu_certificazione_unica_2023.pdf",
      coordinates: [],
      totalPages: 0,
      pdfWidth: 595,
      pdfHeight: 842,
      scale: 1.5,
      pages: {}, // Cache delle pagine PDF
      titolo: "Set Position Sign Document"
    };
  },
  mounted() {
    this.loadPdf();
  },
  methods: {
    async loadPdf() {
      pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

      try {
        const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
        this.totalPages = pdf.numPages;

        for (let pageNumber = 1; pageNumber <= this.totalPages; pageNumber++) {
          const page = await pdf.getPage(pageNumber);
          this.pages[pageNumber] = page;
          const viewport = page.getViewport({ scale: this.scale });

          const pdfCanvas = document.getElementById(`pdf-canvas-${pageNumber}`);
          pdfCanvas.width = viewport.width;
          pdfCanvas.height = viewport.height;
          const context = pdfCanvas.getContext('2d');

          await page.render({
            canvasContext: context,
            viewport: viewport
          }).promise;
        }
      } catch (error) {
        console.error('Errore nel caricamento del PDF:', error);
      }
    },
    async handleCanvasClick(event, pageNumber) {
      const page = this.pages[pageNumber];
      const viewport = page.getViewport({ scale: this.scale });
      const canvas = event.currentTarget;
      const rect = canvas.getBoundingClientRect();

      console.log("Canvas Width:", rect.width);
      console.log("Canvas Height:", rect.height);


      // Calcola l'offset cumulativo delle pagine sopra la pagina corrente
      let cumulativeOffset = 0;
      for (let i = 1; i < pageNumber; i++) {
        const previousPage = this.$refs[`pdfPage-${i}`][0];
        cumulativeOffset += previousPage.getBoundingClientRect().height;
      }

      // Calcola le coordinate relative
      const x = (event.clientX - rect.left) * (viewport.width / rect.width);
      const y = (event.clientY - rect.top + cumulativeOffset) * (viewport.height / rect.height);

      console.log("COORD X: ", x);
      console.log("COORD Y: ", y);

      this.coordinates.push({
        canvasX: x,
        canvasY: y,
        page: pageNumber
      });
    },
    removeHighlight(index) {
      this.coordinates.splice(index, 1);
    },
    sendCoordinates() {
      fetch('http://localhost:5000/api/save_coordinates', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.coordinates)
      })
        .then(response => response.json())
        .then(data => {
          console.log('Coordinate inviate con successo:', data);
        })
        .catch(error => {
          console.error('Errore durante l\'invio delle coordinate:', error);
        });
    }
  }
}
</script>

<style>
.mainTitleConfSign {
  padding: 20px !important;
  position: fixed;
  min-width: 500px;
  z-index: 1;
  top: 67px;
  border: 1px solid black;
}

.highlight-box {
  width: 100px;
  height: 50px;
  background-color: rgba(255, 0, 0, 0.5);
  border: 1px solid red;
  border-radius: 4px;
  cursor: pointer;
}

.close-box {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: white;
  background-color: black;
  border-radius: 50%;
  cursor: pointer;
  padding: 2px;
}
</style>
